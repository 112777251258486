import Alpine from "alpinejs";
import Cookies from 'js-cookie';
import {anchorLink} from "./alpine/anchorLink";
import {getIndexByHref, scrollToHref, scrollToIndex} from "./scroll";

Alpine.data('anchorLink', anchorLink);

Alpine.store('menu', {
    isOpen: false,
    open() {
        this.isOpen = true;
    },
    close() {
        this.isOpen = false;
    },
});

const cookieColorIndex = Cookies.get('bgColorIndex');
Alpine.store('colorChooser', {
    active: typeof cookieColorIndex !== 'undefined' ? Number(cookieColorIndex) : 1,
    classes: ['bg-bglight', 'bg-bgmiddle', 'bg-bgdark'],
    get activeClass() {
        return this.classes[this.active];
    },
    activate(i) {
        Cookies.set('bgColorIndex', i)
        this.active = i;
    },
});

Alpine.store('scroll', {
    active: 0,
    lastIndex: document.getElementsByClassName('contentPage').length - 1,
    get isFirst() {
        return this.active === 0;
    },
    get isLast() {
        return this.active === this.lastIndex;
    },
    setActive(i) {
        //console.log('set active', i);
        this.active = i;
    },
    gotoNext() {
        const i = Math.min(this.lastIndex, this.active + 1);
        this.setActive(i);
        scrollToIndex(i);
    },
    gotoPrev() {
        const i = Math.max(0, this.active - 1);
        this.setActive(i);
        scrollToIndex(i);
    },
    gotoHref(href) {
        const i = getIndexByHref(href);
        this.setActive(i);
        scrollToHref(href);
    }
});

Alpine.start();
